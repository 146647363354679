:root {
    --t-background: #f5f5f5;
    --t-background-footer: #fff;
    --t-section-background: transparent;
    --t-section-background-accent: #e7e7eb;
    --t-section-background-primary: #D6EED9;
    --t-text-background: #fff;
    --t-text-color: #707070;
    --t-text-background-light: var(--t-background);
    --t-text-color-accent: var(--bs-gray-900);
    --t-text-color-primary: var(--bs-primary);

    --t-navbar-height: 88px;

    /* What to substract from 100vh to start the next elemment after fullsize header */
    --t-fullsize-modifier: 150px;

    --t-header-margin: 0 0 20px 0;
    --t-paragraf-margin: 0 0 20px 0;

    --t-section-padding: 40px 0;
    --t-section-inner-padding: 3rem;

}
