@font-face {
  font-family: 'Ubuntu-R';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("https://cdn.leuffen.de/hyperpage-components/v1.0/fonts/ubuntu/Ubuntu-R.ttf");
}
@font-face {
  font-family: 'Ubuntu-R';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url("https://cdn.leuffen.de/hyperpage-components/v1.0/fonts/ubuntu/Ubuntu-Th.ttf");
}
@font-face {
  font-family: 'Ubuntu-R';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("https://cdn.leuffen.de/hyperpage-components/v1.0/fonts/ubuntu/Ubuntu-M.ttf");
}


// Ubuntu Light
@font-face {
  font-family: 'Ubuntu-Th';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("https://cdn.leuffen.de/hyperpage-components/v1.0/fonts/ubuntu/Ubuntu-Th.ttf");
}
@font-face {
  font-family: 'Ubuntu-Th';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("https://cdn.leuffen.de/hyperpage-components/v1.0/fonts/ubuntu/Ubuntu-R.ttf");
}
