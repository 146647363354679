

.tjs__hero-max {
    --joda-class: ":: mobile :lg: desktop";

    --layout-image-ratio: 6 / 4;

    &.mobile {
        &>slot {
            display: block;
            position: relative;
            aspect-ratio: var(--layout-image-ratio, 5 / 4);
        }
        & > slot > img {
            object-fit: cover;
            height: 100%;
            width: 100%;
        }

        & > .tjs__hero-max--out-wrapper {
            position: relative;

            display: flex;
            align-items: center;
            justify-content: center;
            padding-top: 4rem;
            padding-bottom: 4rem;

        }
    }
    &.desktop {

        &.box {
            .content {
                background: rgba(255, 255, 255, 0.8);
                padding: var(--content-padding, 1rem);
            }

        }

        position: relative;
        &.tjs__hero-max--autoheight {
            height: calc(100vh - var(--t-fullsize-modifier, 200px));
            min-height: var(--layout-min-height, 400px);
            max-height: var(--layout-max-height, 1000px);
        }
        &>slot {
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            width: 100%;
            & > img {
                height: 100%;
                object-fit: cover;
                width: 100%;
            }
        }

        & > .tjs__hero-max--out-wrapper {
            position: absolute;
            top: 0;
            bottom: 0;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0;

        }
    }


    width: 100%;
    align-items: center;
    position: relative;
    justify-content: center;
    overflow: hidden;



    h1 {
        font-size: calc(1.5em + 1vw);
    }








    .tjs__hero-max--section-text {
        --joda-class: "col-12 col-lg-7";
    }

    .offsetmodifier {
        height: 100px;
    }
}
