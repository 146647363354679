

.tjs__sec-card-2col {
    padding: var(--t-section-padding);
    background: var(--t-section-background);


    .row > .tjs__sec-card-2col--col-start  {
        --joda-class: "col-12 col-md-6 p-4 p-lg-5 order-2 order-md-1";
    }

    .row > .tjs__sec-card-2col--col-end  {
        --joda-class: "col-12 col-md-6 order-1 order-md-2";

        img {
            height: 100%;
            width: 100%;
            object-position: center center;
            object-fit: cover;
        }
    }


    .tjs__sec-card-2col--container {
          border: 1px solid var(--bs-gray-500);
        background-color: white;
        padding: 0;
    }

}

.section-h2:nth-of-type(odd) .tjs__sec-card-2col .row {
    flex-direction: row-reverse;
    --joda-class: "flex-row-reverse";
}
