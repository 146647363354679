


showcase-element {
  --aspect-ratio: calc(16 / 9);
  &.big {
    --aspect-ratio: 0.5;
  }
  .preview {
    --scale: 0.2;

    --width: 350px;
    --height: calc(var(--width) / var(--aspect-ratio));
    position: relative;
    width: var(--width);
    height: var(--height);
    overflow: hidden;
    display: inline-block;


    border: 3px solid #ccc;
    iframe {
      position: relative;
      right: calc((var(--width) / var(--scale) / 2) - var(--width) / 2);
      bottom: calc((var(--height) / var(--scale) / 2) - var(--height) / 2);
      width: calc(var(--width) / var(--scale));
      height: calc(var(--height) / var(--scale));
      transform: scale(var(--scale));
    }

    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.5);
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      font-size: 1.5rem;
      font-weight: bold;
      text-align: center;
      opacity: 0;
      transition: opacity 0.3s ease-in-out;
      cursor: pointer;
      &:hover {
        opacity: 1;
      }
    }
  }

}
