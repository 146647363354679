

.tjs__sec-card-feature {

    padding: var(--t-section-padding);

    background: var(--t-section-background);
    .header_image {
        aspect-ratio: 21 / 10;
        width: 100%;
        height: auto;
    }

    &.map .header_image {
        aspect-ratio: 1/1;
        max-height: 90vh;

    }

     .header_image > img {
        object-fit: cover;
        object-position: center center;
        width: 100%;
        height: 100%;

    }

    & > .container {
        border: 1px solid var(--bs-gray-500);
        border-bottom: 4px solid var(--bs-gray-500);
        background-color: white;
        padding: 0;
    }

}
