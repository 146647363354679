.tjs__e-card-default {
    height: 100%;
    min-width: 300px;

    &.card {
        height: 100%;
    }
    .card-body {
       height: 100%;
    }
    img {
        width: 100%;
        height: 100%;
        --joda-class: card-img-top;
        aspect-ratio: 16 / 9;
        object-fit: cover;
    }
}
