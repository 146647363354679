@mixin default-element-format() {
    section h2 {
        text-transform: uppercase;
        // font-weight: bold;

        margin: var(--t-paragraf-margin);
    }

    ul > li  {
        color: var(--t-text-color);
        * {
            color: var(--t-text-color);
        }
    }

    h1,h2,h3,h4,h5,h6 {
        font-weight: 400;
        color: var(--t-text-color);
        margin: var(--t-header-margin, 0 0 20px 0);
    }

    h1,h2 {
        font-size: 2em;
        line-height: 1.5em;
    }
    h3 {
        font-size: 1.5em;
        line-height: 1.1em;
    }


    table {
        color: inherit;
        &> tr {
            color: inherit;
            &>td {
                color: inherit;
            }
        }
    }



    // Paragraph styling
    p:empty {
        margin: 0;
    }

    p {
        margin: var(--t-paragraf-margin);
        hyphens: auto; // Wortumbruch
    }

    p:first-child {
        //color: var(--t-text-color-accent)
    }

    // Important: Remove margin from last paragraph in section
    p:last-child,table:last-child {
        margin-bottom: 0;
    }


    address {
        p {
            margin: 0;
        }
    }
    section:last-of-type > * {
        padding-bottom: 0;
    }

}
