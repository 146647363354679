body > .loader > .loader-bar {
    position: relative;
    display: block;
    background-color: lightgrey;
    height: 3px;
    width: 70vw;
    max-width: 500px;
    top: 0;
    z-index: 1;
    margin-top: 25vh;
    &::before {
        content: "";
        font-family: sans-serif;
        font-size: 12px;
        line-height: 10px;
        position: relative;
        text-align: center;
        color: white;

        z-index: 2;
        -webkit-animation: leu--site-loader 60s ease-out;
        animation: leu--site-loader 30s ease-out;
        display: block;
        background-color: indianred;
        height: 100%;
        width: 35%;
        top: 0;
    }
}


@keyframes leu--site-loader {
    3% {
        width: 75%;

    }
    5% {
        width: 90%;
    }
    10% {
        width: 95%;
    }
    100% {
        width: 100%;
    }
}


body {
    min-height: 5000vh; // 3000vh is enough to make sure the page scrolls to correct position on reload
}

body.loaded {
    min-height: unset;
}

body > joda-content {
    opacity: 0;
    transition: opacity 0.10s;
}
body.loaded > joda-content {
    opacity: 1;
}


body > .loader {
    position: fixed;
    z-index: -99;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #fff;
    transition: opacity 0.15s;
    display: flex !important;

    align-items: center;
    align-content: center;
    justify-content: center;
    opacity: 1;
    flex-wrap: wrap;
    flex-direction: column;

    & > img {
        flex-direction: column;
        display: block !important;
        height: auto !important;
        width: 70vw !important;
        max-width: 500px;

    }
}

body.loaded > .loader {
    opacity: 0;
    //display: none !important;
    z-index: -99;
}
