

.tjs__navbar-blox {
    --height: var(--t-navbar-height, 64px);
    position: relative;
    top: 0;
    width: 100%;
    z-index: 9999;
    --hamburger-menu-display: none;
    --hamburger-bar-color:  #aaa;



    .firstline {
        min-height: var(--height);
        line-height: var(--height);
        --joda-class: ":: d-block :lg: d-none";


        .tjs__content {
            width: 100%;
            display: flex;
            justify-content: center;
        }
    }


    .tjs__row1 {
        height: var(--height);

        display: flex;
        width: 100%;


        &>.tjs__logo {
            &.mobile {
                display: none;
            }
            flex: 0 0 auto;
            width: auto;
            display: flex;
        }

        .tjs__row2 {
            flex: 1 1 auto;
            display: flex;
            height: 100%;

            &> .tjs__nav-main {
                flex: 1 1 auto;

                display: flex;
                align-items: center;
                align-content: center;

                &> ul.nav-bar-main {
                    display: flex;
                    margin: 0;
                    padding: 0;
                    width: 100%;
                    height: 100%;
                    &>li {
                        flex: 1 1 auto;
                        width: 100%;
                        display: flex;
                        border-right: 1px solid var(--bs-gray-200);
                        &:last-of-type {
                            border-right: none;
                        }

                        align-content: center;
                        align-items: center;

                        padding: 0;
                        list-style: none;
                        text-decoration: none;
                        text-align: center;
                        a {
                            width: 100%;
                            text-decoration: none;
                            color: inherit;
                            i {
                                color: var(--bs-primary);
                                padding-right: 5px;
                            }
                        }
                    }
                }

            }
            &> .tjs__hamburger {
                flex: 0 0 auto;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 70px;
                height: 100%
            }

        }
    }

    .sidenav {
         position: absolute;
        right: 0;
        z-index: 9999;
        top: 0;
        width: auto;
        padding: 0;
        background-color: white;
        transform: scale(0%);
        -webkit-transform: scale(0, 0);

        transition: transform 0.3s ease-in-out;
        box-shadow: 0px 1px 2px #00000029;

        ul {
            padding: 0;
            margin: 0;
            width: 350px;
            max-width: 100vw;
            &>li {
                 list-style: none;
                font-weight: bolder;
                margin: 3px;
                &>a {
                    display: block;
                    padding: 15px 30px;
                    text-decoration: none;
                    &:hover {
                          background-color: var(--t-text-background-light);
                    }
                    &:focus {
                        color: var(--t-text-color-primary);
                    }
                }
            }
        }

    }

    .line {


        a {
            color: var(--bs-gray-700);
            font-weight: normal;
            text-decoration: none;
            i {
                  color: var(--bs-primary);
                border-radius: 100%;
                width: 20px;
                height: 20px;
                --joda-class: ":: :xl: pe-2";
            }
        }
        &.bordered {
              border-right: 1px solid var(--bs-gray-200);
            overflow: hidden;
            --joda-class: "text-center";
        }
    }


    .sidenav-positioner {
        position: relative;
    }

}



.nav-open .tjs__navbar-blox .sidenav {
     transform: scale(100%);
    -webkit-transform: scale(1, 1);

}
