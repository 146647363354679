@import "../../variables";
@import "../_mixins/default-element-format";

:root {
    --layout-container: container;
}
.theme1 {
    @include default-element-format();

    color: var(--t-text-color);
    background: var(--t-background);


    h1,h2,h3,h4,h5 {
        color: var(--t-text-color);
    }

    h1 {
        font-size: 2.4em;
        line-height: 1.2em;
    }
    h2, h3,h4,h5 {
        line-height: 1.2em;
    }
    h3,h4,h5 {
        font-size: 1.15em;
    }

    p {
        line-height: 1.4em;
    }

    .tjs__section-text {
        h2 {
            text-transform: uppercase;
            font-weight: bold;
            font-size: 16px;
            color: var(--t-text-color-primary) !important;
            margin: var(--t-paragraf-margin);
        }
        blockquote > p {
            font-size: 2.1em;
            line-height: 1.2em;
            color: var(--t-text-color) !important;
            margin: var(--t-header-margin, 0 0 20px 0);
            hyphens: manual;
        }

        .table {
            --bs-table-color: var(--t-text-color);
        }

        *:last-child {
            margin-bottom: 0;
        }
    }

    .section {
        blockquote p {
            font-size: 2.3em;
        }
    }


    .card-body {
        h3 {
            font-weight: bold;
        }
        color: var(--t-text-color);
    }

    font-size: 1.1rem;

    --t-background: #f5f5f5;
    --t-background-footer: #fff;
    --t-section-background: transparent;
    --t-section-background-accent: #e7e7eb;
    --t-section-background-primary: #{$background-primary};
    --t-text-background: #fff;
    --t-text-color: #707070;
    --t-text-background-light: var(--t-background);
    --t-text-color-accent: var(--bs-gray-900);
    --t-text-color-primary: var(--bs-primary);


    --t-header-margin: 0 0 20px 0;
    --t-paragraf-margin: 0 0 20px 0;

    --t-section-padding: 40px 0;
    --t-section-inner-padding: 3rem;

    .tjs__sec-legal-content {
        padding-top: 100px;
    }

    section:last-child {
        padding-bottom: 0;
    }

    //    background: var(--bs-light);

    a.btn.btn-primary {
        color: white !important;
    }
    input.btn.btn-primary {
        color: white !important;
    }

    .btn {
        border-radius: 0;
    }

    map {
        --joda-class: ":: aspect-1x1 :lg: aspcect-16x9";
    }


    .title_image {
        background: linear-gradient(180deg, rgba(230,225,221,1) 0%, rgba(245,240,236,1) 35%, rgba(238,238,238,1) 100%);;
        & > img {
            object-position: 80% 0;
            object-fit: contain;
        }
    }

    .as__accordion {
        border: 1px solid var(--bs-gray-100);
        --as-accordion-open-bg: #fff;
        h3 {
            padding-top: 10px;
            padding-bottom: 10px;
        }
        .section-h3 {
            border: 1px solid var(--bs-gray-200);
            background-color: var(--bs-gray-100);
        }
        .content {
            background-color: #fff;
            p {
                margin: 20px 20px 20px 0;
            }
        }

    }


}
.openhours {
    --joda-class: "table table-borderless";
}
